import React from 'react';
import Router from 'next/router';
import Typography from "../typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./style.js"; 

export default function NotWorking(props) {
  
  const {title, contentOne, contentTwo} = props;
  const classes = useStyles();
  function goto(e){
    let screen = e;
    Router.push(screen)
  }

  return (
    <Grid aling="center"> 
      <Grid item className={classes.containerNoFound}>
        <Typography
          align="center"
          variant="h1"
          marked="center"
          className={classes.tittle}
        >{title}</Typography>
        <Typography
          align="center"
          variant="h5"
          marked="center"
          className={classes.subtitleline1}
        >{contentOne}</Typography>
        <Typography
          align="center"
          variant="h5"
          marked="center"
          className={classes.subtitleline2}
        >{contentTwo}</Typography> 
        <Button className={classes.bottomHome} size="large" onClick={() => goto("/")}>Inicio</Button> 
      </Grid>
    </Grid>
  );
}
