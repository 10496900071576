import Head from "next/head";
import Grid from "@material-ui/core/Grid";
import Appbar from "../components/appBar";
import NotWorking from "../components/notWorking";
import Footer from "../components/footer";

export default function Custom404() {
  return (
    <Grid>
      <Head>
        <title>404 - Página no encontrada</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Appbar />
      <NotWorking
        title="404"
        contentOne="No hemos localizado la página que buscas."
        contentTwo="Dirigete a inicio para otras opciones"
      />
      <Footer />
    </Grid>
  );
}
