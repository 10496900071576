import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  containerNoFound: {
    height: "calc(100vh - 70px)",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 82px)",
    },
    minHeight: 600,
    maxHeight: 1300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: 'linear-gradient(rgba(41, 67, 116, 0.55), rgba(41, 67, 116, 0.55)), url("/images/maintenence-NoFound/noFound6.jpg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    fontFamily: 'Century-Gothic',
    fontSize: 'calc(10px + 2vmin) !important',
    fontWeight: 'bold',
  },
  bottomHome: {
    color: "white",
    background: "#33658A",
    paddingBlockStart: "10px",
    paddingBlockEnd: "10px",
    paddingInlineStart: "40px",
    paddingInlineEnd: "40px",
    fontWeight: "bold",
    fontFamily: "Century-Gothic",
    "&:hover": {
      backgroundColor: "#33658A",
    },
  },
  tittle: {
    fontFamily: "Century-Gothic",
    color: "white",
    fontWeight: "bold",
    marginBottom: theme.spacing(10),
  },
  subtitleline1: {
    color: "white",
    fontWeight: "bold",
    fontFamily: "Century-Gothic",
  },
  subtitleline2: {
    color: "white",
    fontWeight: "bold",
    fontFamily: "Century-Gothic",
    marginBottom: theme.spacing(5),
  },
}));
export default useStyles;
